import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "../components/fuminoki.module.scss"

import about from "../images/svg/about3.svg"
import fuminoki from "../images/svg/fuminoki01.svg"

const IndexPage = () => (
  <>
    <header>
      <div className={styles.logo}>
        <StaticImage
          src="../images/logo/fuminoki-logo-green7-2.svg"
          alt="FUMINOKIのロゴ"
        />
      </div>
      <h1>
        <img src={fuminoki} alt="FUMINOKI" />
      </h1>
      <p>
        <small>&copy;2021Fumi</small>
      </p>
    </header>
    <main>
      <div id="first" className={styles.first_block}>
        <div className={styles.wrapper}>
          <span className={styles.shooting_star}></span>
        </div>
        <div id="particles_js"></div>
      </div>
      <div id="second" className={styles.second_block}>
        <h2 className={styles.block_ttl}>
          <img src={about} alt="about" width="120" height="32" />
        </h2>
        <p className={styles.block_desc}>
          当サイトは、クリエイターFumiのポートフォリオサイトです。
        </p>
        <div className={styles.message_wrap}>
          <div className={styles.about_message}>
            <p>
              <span className={styles.messa_accent}>Fu</span>ture{" "}
              <span className={styles.messa_accent}>mi</span>racles will be{" "}
              <span className={styles.messa_accent}>no</span>nfiction through{" "}
              <span className={styles.messa_word}>kn</span>owledge,{" "}
              <span className={styles.messa_word}>in</span>teligence,{" "}
              <span className={styles.messa_word}>ac</span>tion, and{" "}
              <span className={`${styles.messa_accent} ${styles.yellow}`}>ki</span>
              ndness.
            </p>
            <p className={styles.about_jp}>
              未来の奇跡は知識、知恵、行動、そして優しさによってノンフィクションになる。
            </p>
          </div>
        </div>
      </div>
    </main>
  </>
)

export default IndexPage
