// extracted by mini-css-extract-plugin
export var about_jp = "fuminoki-module--about_jp--BVqtV";
export var about_message = "fuminoki-module--about_message--U4H6g";
export var block_desc = "fuminoki-module--block_desc--lhoBP";
export var block_ttl = "fuminoki-module--block_ttl--2KN0e";
export var circle = "fuminoki-module--circle--aAmba";
export var circle_container = "fuminoki-module--circle_container--sG4GA";
export var first_block = "fuminoki-module--first_block--a6XBE";
export var logo = "fuminoki-module--logo--In3GG";
export var messa_accent = "fuminoki-module--messa_accent--nXfo5";
export var messa_word = "fuminoki-module--messa_word--zhI7T";
export var message_wrap = "fuminoki-module--message_wrap--AY8oM";
export var nav_works = "fuminoki-module--nav_works--Me05v";
export var particles_js = "fuminoki-module--particles_js--7yvqW";
export var second_block = "fuminoki-module--second_block--t4pZ7";
export var shooting = "fuminoki-module--shooting--PXGw8";
export var shooting_star = "fuminoki-module--shooting_star--fn1CE";
export var spread = "fuminoki-module--spread---rvQF";
export var under_nav = "fuminoki-module--under_nav--Nsxhu";
export var wrapper = "fuminoki-module--wrapper--4lFvb";
export var yellow = "fuminoki-module--yellow--REotV";